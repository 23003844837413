import { Box, Button, Stack } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { l_flutter } from "../../locales";
import FlutterTypography from "../../theme/FlutterTypography";
import ButtonDotIcon from "../../theme/ButtonDotIcon";
import { LocalizedLink } from "../../../../components/LocalizedLink";

const ListOfCases = () => {
  const cases = [
    {
      link: "/works/nowr",
      description: l_flutter("caseStudies.caseDescription1Nowr"),
      logo: (
        <Box sx={{ maxWidth: { md: "198px", xs: "91px" } }}>
          <StaticImage
            alt="nowr logo"
            src="../../images/nowr-logo.png"
            width={198}
          />
        </Box>
      ),
    },
    {
      link: "/works/belvillage",
      description: l_flutter("caseStudies.caseDescription2Belvillage"),
      logo: (
        <Box sx={{ maxWidth: { md: "220px", xs: "115px" } }}>
          <StaticImage
            alt="belvillage logo"
            src="../../images/belvillage-logo.png"
            width={230}
          />
        </Box>
      ),
    },
    {
      link:
        "https://docs.google.com/presentation/d/101GSFWRzA-2fxQ0YLULeGLdWF6-J4P0n5XDFkDJWpgk/present?slide=id.g220d57fbda0_1_25",
      description: l_flutter("caseStudies.caseDescription3AngelEye"),
      logo: (
        <Box sx={{ maxWidth: { md: "230px", xs: "120px" } }}>
          <StaticImage
            alt="angel eye logo"
            src="../../images/angeleye-logo.png"
            width={240}
          />
        </Box>
      ),
    },
  ];

  return (
    <Stack
      sx={{
        width: "100%",
        flexDirection: "column",
        gap: { md: "48px", xs: "16px" },
      }}
    >
      {cases.map((caseStudy, i) => (
        <Box
          key={`casestudy${i}`}
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            padding: { md: "48px", xs: "24px 32px" },
            boxSizing: "border-box",
            borderRadius: { md: "48px", xs: "30px" },
            display: "flex",
            gap: { md: "64px", xs: "24px" },
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "space-between",
            alignItems: { md: "center", xs: "flex-start" },
          }}
        >
          <Box sx={{ width: { md: "30%", xs: "unset" }, maxWidth: "360px" }}>
            {caseStudy.logo}
          </Box>
          <Box
            sx={{
              flex: 1,
              minWidth: "30%",
              maxWidth: { md: "360px", xs: "unset" },
            }}
          >
            <FlutterTypography
              sx={{
                fontSize: { md: "20px", xs: "16px" },
                lineHeight: { md: "32px", xs: "20px" },
                fontWeight: 400,
              }}
            >
              {caseStudy.description}
            </FlutterTypography>
          </Box>
          <LocalizedLink
            to={caseStudy.link}
            style={{
              textDecoration: "none",
              color: "white",
              width: { md: "30%", xs: "100%" },
              minWidth: "250px",
              maxWidth: { md: "360px", xs: "420px" },
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: "#E85322",
                backgroundColor: "transparent",
                borderRadius: "56px",
                fontSize: "16px",
                lineHeight: "16px",
                fontWeight: 700,
                width: "100%",
                padding: { md: "24px 0px", xs: "12px 24px" },
                borderWidth: "2px",

                alignSelf: "center",
                ":hover": {
                  borderColor: "inherit",
                  backgroundColor: "#E8532212",
                  borderWidth: "2px",
                },
              }}
              //href="#openPositions"
              startIcon={<ButtonDotIcon />}
            >
              {l_flutter("caseStudies.buttonText")}
            </Button>
          </LocalizedLink>
        </Box>
      ))}
    </Stack>
  );
};

export default ListOfCases;
