import { Box } from "@mui/material";
import FlutterTypography from "../../theme/FlutterTypography";
import { l_flutter } from "../../locales";
import Title from "./Title";
import ListOfCases from "./ListOfCases";

const CaseStudies = () => {
  return (
    <Box
      sx={{
        maxWidth: "1800px",
        width: "90%",
        margin: "auto",
        marginBottom: { md: "120px", xs: "40px" },
      }}
    >
      <Title />
      <ListOfCases />
    </Box>
  );
};

export default CaseStudies;
