import { Box, Button } from "@mui/material";

import gsap, { Power3 } from "gsap";

import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { StaticImage } from "gatsby-plugin-image";
import { useEffect } from "react";
import { l_flutter } from "../../locales";
import ButtonDotIcon from "../../theme/ButtonDotIcon";
import { LocalizedLink } from "../../../../components/LocalizedLink";
gsap.registerPlugin(ScrollToPlugin);

const Description = () => {
  useEffect(() => {
    const animatePurpleLemon = gsap.fromTo(
      ".are-you-interested-purple-lemon",
      {
        x: 0,
        y: 0,
        rotate: -30,
      },
      {
        scrollTrigger: {
          trigger: ".are-you-interested-purple-lemon",
          scrub: 2,
        },
        x: 40,
        y: -80,
        rotate: 80,
      }
    );
    const animateOrangeLemon = gsap.fromTo(
      ".are-you-interested-orange-circle",
      {
        x: 0,
        y: 0,
      },
      {
        scrollTrigger: {
          trigger: ".are-you-interested-orange-circle",
          scrub: 2,
        },
        x: -60,
        y: -10,
      }
    );
    const animateBlueLemon = gsap.fromTo(
      ".are-you-interested-blue-lemon",
      {
        x: 0,
        y: 0,
        rotate: 130,
      },
      {
        scrollTrigger: {
          trigger: ".are-you-interested-blue-lemon",
          scrub: 2,
        },
        x: -90,
        y: 40,
        rotate: 40,
      }
    );

    return () => {
      animatePurpleLemon.kill();
      animateOrangeLemon.kill();
      animateBlueLemon.kill();
    };
  }, []);

  return (
    <Box
      sx={{
        width: { md: "45%", xs: "100%" },
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <LocalizedLink
        to={"/contacts"}
        style={{
          textDecoration: "none",
          color: "white",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderColor: "#E85322",
            backgroundColor: "transparent",
            borderRadius: "56px",
            fontSize: "16px",
            lineHeight: "16px",
            fontWeight: 700,
            padding: { md: "24px 46px", xs: "12px 24px" },
            borderWidth: "2px",
            width: { xs: "100%", md: "auto" },
            maxWidth: "400px",
            alignSelf: "center",
            ":hover": {
              borderColor: "inherit",
              backgroundColor: "#E8532212",
              borderWidth: "2px",
            },
          }}
          //href="#openPositions"
          startIcon={<ButtonDotIcon />}
          onClick={() => {
            // gsap.set(window, { scrollTo: "#openPositions" });
            gsap.to(window, {
              duration: 1,
              scrollTo: "#openPositions",
              ease: Power3.easeInOut,
            });
          }}
        >
          {l_flutter("areYouInterested.buttonText")}
        </Button>
      </LocalizedLink>
      <>
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            left: "10%",
            rotate: "-17deg",
            display: { md: "block", xs: "none" },
          }}
          className="are-you-interested-purple-lemon"
        >
          <StaticImage alt="" src="../../images/purple-lemon.svg" width={32} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "40px",
            right: "20%",
            display: { md: "block", xs: "none" },
          }}
          className="are-you-interested-orange-circle"
        >
          <StaticImage alt="" src="../../images/orange-circle.svg" width={36} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "30px",
            right: "0px",
            rotate: "130deg",
            display: { md: "block", xs: "none" },
          }}
          className="are-you-interested-blue-lemon"
        >
          <StaticImage alt="" src="../../images/blue-lemon.svg" width={48} />
        </Box>
      </>
    </Box>
  );
};
export default Description;
