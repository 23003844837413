import { Box, Button } from "@mui/material";

import gsap, { Power3 } from "gsap";

import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { l_flutter } from "../../locales";
import ButtonDotIcon from "../../theme/ButtonDotIcon";
import FlutterTypography from "../../theme/FlutterTypography";
import { LocalizedLink } from "../../../../components/LocalizedLink";
gsap.registerPlugin(ScrollToPlugin);

const Description = () => {
  return (
    <Box
      sx={{
        // padding: "24px",
        width: { md: "54%", xs: "100%" },
        maxWidth: "780px",
        marginLeft: { md: "auto", xs: "unset" },
        boxSizing: "border-box",
        display: { md: "block", xs: "flex" },
        flexDirection: "column",
      }}
    >
      <FlutterTypography variant="body1">
        {l_flutter("lookingForDevs.description")}
      </FlutterTypography>
      <LocalizedLink
        to={"/contacts"}
        style={{
          textDecoration: "none",
          color: "white",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            marginTop: "32px",
            borderColor: "#E85322",
            backgroundColor: "transparent",
            borderRadius: "56px",
            fontSize: "16px",
            lineHeight: "16px",
            fontWeight: 700,
            padding: { md: "24px 46px", xs: "12px 24px" },
            borderWidth: "2px",
            width: { xs: "100%", md: "auto" },
            maxWidth: "400px",
            alignSelf: "center",
            ":hover": {
              borderColor: "inherit",
              backgroundColor: "#E8532212",
              borderWidth: "2px",
            },
          }}
          //href="#openPositions"
          startIcon={<ButtonDotIcon />}
          onClick={() => {
            // gsap.set(window, { scrollTo: "#openPositions" });
            gsap.to(window, {
              duration: 1,
              scrollTo: "#openPositions",
              ease: Power3.easeInOut,
            });
          }}
        >
          {l_flutter("lookingForDevs.buttonText")}
        </Button>
      </LocalizedLink>
    </Box>
  );
};
export default Description;
