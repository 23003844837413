import { HeadProps } from "gatsby";
//@ts-ignore
import locales from "../../locales";
//@ts-ignore
import coverImage from "../../images/cover.png";
import { language } from "../../traductions";

const siteMetadata = {
  title: `Mabiloft`,
  // description: `App Web Design`,
  description: `UX-driven Software House`,
  author: `@mabiloft`,
  siteUrl: `https://mabiloft.com/`,
};

export function initHeadLanguage(headProps: HeadProps) {
  // language.code = (headProps.pageContext as any)?.locale?.substring(0, 2) || "en";
  if (headProps.location.pathname.includes("/it/")) {
    language.code = "it";
  } else {
    language.code = "en";
  }
}

function SEOHead({
  description,
  meta,
  title,
  image,
  article,
  noIndex,
  headProps,
}: {
  title: string;
  description: string;
  image?: string;
  meta?: {
    property: string;
    content: any;
    name?: undefined;
  }[];
  article?: { [key: string]: string };
  noIndex?: boolean;
  headProps: HeadProps;
}) {
  const coverIT = require("../../images/cover.png");
  const coverEN = require("../../images/cover_en.png");

  const metaDescription = description || siteMetadata.description;
  const metaImage =
    image || coverImage || (language.code === "en" ? coverEN : coverIT);

  const widthImage = "1024";
  const heightImage = image ? "1024" : "541";

  const metaTags = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `image`,
      content: metaImage,
    },
    {
      property: `og:image`,
      content: metaImage,
    },
    {
      property: `og:image:width`,
      content: widthImage,
    },
    {
      property: `og:image:height`,
      content: heightImage,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:author`,
      content: `Mabiloft`,
    },
    {
      property: `og:site_name`,
      content: `Mabiloft`,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ];

  if (article) {
    let articleKeys = Object.keys(article);
    articleKeys.forEach((key) => {
      metaTags.push({
        name: `article:${key}`,
        content: article[key],
      });
    });
  }

  if (noIndex && noIndex === true) {
    metaTags.push({
      name: `robots`,
      content: `noindex`,
    });
  }

  if (meta) {
    metaTags.push(...meta);
  }

  //material-ui viewport
  // metaTags.push({
  //   name: "viewport",
  //   content: "initial-scale=1, width=device-width"
  // });

  return (
    <>
      <title children={title} />

      <meta name="mabi_description" content={headProps.location.pathname} />
      <meta name="mabi_lang" content={language.code} />

      <html lang={language.code} />

      {/* <Helmet
            htmlAttributes={{ lang: language.code }}
            title={title}
            // titleTemplate={`${site.siteMetadata.title} • %s `}
            meta={meta ? metaTags.concat(meta) : metaTags}
          > */}

      {metaTags.map((metaTag, index) => {
        return <meta key={index} {...metaTag} />;
      })}

      {Object.keys(locales).map((loc) => {
        // const path = location.pathname.replace(`/${locale}/`, "/");
        const path = headProps.location.pathname.replace(`/it/`, "/");
        const href =
          "mabiloft.com" + (locales[loc].default ? "" : `/${loc}`) + path;

        return (
          <link
            key={loc}
            rel="alternate"
            hrefLang={locales[loc].default ? "x-default" : loc}
            href={href}
          />
        );
      })}

      {/* <link rel="canonical" href={location.href} /> */}
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Fira+Code&family=Metal&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Fira+Code&family=Metal&family=Petrona:wght@500&display=swap"
        rel="stylesheet"
      />
    </>
  );
}

SEOHead.defaultProps = {
  title: siteMetadata.title,
  lang: `en`,
  meta: [],
  description: siteMetadata.description,
};

export default SEOHead;
