import { Box, Stack } from "@mui/material";

import FlutterTypography from "../../theme/FlutterTypography";
import { l_flutter } from "../../locales";

const Title = () => {
  return (
    <Stack
      sx={{
        padding: "24px",
        flex: 1,
        justifyContent: "center",
        gap: { md: "32px", xs: "16px" },
        boxSizing: "border-box",
      }}
    >
      <FlutterTypography variant="overtitle" sx={{ fontWeight: 700 }}>
        {l_flutter("header.overtitle")}
      </FlutterTypography>
      <FlutterTypography
        sx={{
          fontSize: { lg: "96px", md: "64px", xs: "36px" },
          fontWeight: 700,
          lineHeight: { lg: "116px", md: "88px", xs: "40px" },
        }}
      >
        {l_flutter("header.title")}
      </FlutterTypography>

      <FlutterTypography variant="body1" sx={{ letterSpacing: "-0.75px" }}>
        {l_flutter("header.subtitle")}
      </FlutterTypography>
    </Stack>
  );
};
export default Title;
