import { Box, Stack } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { l_flutter } from "../../locales";
import FlutterTypography from "../../theme/FlutterTypography";

const WhyFlutter = () => {
  return (
    <Box sx={{ width: "100%", backgroundColor: "#fff", position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: { md: "-35px", xs: "-20px" },
          right: { md: "5%", xs: "10%" },
          maxWidth: { md: "unset", xs: "75px" },
        }}
      >
        <StaticImage alt="" src="../../images/decoration.png" />
      </Box>
      <Stack
        sx={{
          maxWidth: "1800px",
          width: "90%",
          margin: "auto",
          paddingTop: { md: "80px", xs: "40px" },
          paddingBottom: { md: "80px", xs: "40px" },
          // border: "1px solid red",
        }}
      >
        <FlutterTypography
          variant="overtitle"
          sx={{ marginBottom: { md: "32px", xs: "8px" } }}
        >
          {l_flutter("whyFlutter.overtitle")}
        </FlutterTypography>

        <FlutterTypography
          variant="title2"
          sx={{ maxWidth: "1200px", marginBottom: "32px" }}
        >
          {l_flutter("whyFlutter.title")}
        </FlutterTypography>

        <FlutterTypography variant="body1" sx={{ maxWidth: "1280px" }}>
          {l_flutter("whyFlutter.description")}
        </FlutterTypography>
      </Stack>
    </Box>
  );
};
export default WhyFlutter;
