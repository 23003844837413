import { DeepKeyof, language } from "../../traductions";

const traduction = {
  it: {
    seo: {
      title: "App in Flutter",
      description:
        "Costruiamo insieme la tua app iOS e Android. Con la tua applicazione in Flutter farai decollare il tuo business. Chiedici aiuto per svilupparla!",
    },
    header: {
      overtitle: "Servizi",
      title: "Sviluppo App in Flutter",
      subtitle:
        "Velocizza il processo di sviluppo, migliora la qualità e rendi più mantenibile l’applicazione!",
    },
    lookingForDevs: {
      title:
        "Se stai cercando un team di sviluppatori esperti per la realizzazione di un'applicazione mobile in Flutter, sei nel posto giusto.",
      description:
        "Mabiloft è un'agenzia di sviluppo situata a Padova, specializzata nella realizzazione di app per iOS e Android utilizzando il framework Flutter di Google.",
      buttonText: "Sentiamoci",
    },
    whyFlutter: {
      overtitle: "Tecnologie",
      title: "Perché usiamo Flutter?",
      description:
        "Flutter è un framework di sviluppo di applicazioni mobile creato da Google che consente di scrivere codice una volta sola e utilizzarlo su tutte le piattaforme. Grazie a Flutter, quindi, è possibile sviluppare applicazioni per iOS e Android con una singola codebase, testare una volta sola e modificare la business logic da un punto solo.",
    },
    ourServices: {
      overtitle: "I NOSTRI SERVIZI",
      title: "Cosa possiamo fare per te?",

      gridTitle1Features: "Costruire insieme alcune feature",
      gridDescription1Features:
        "Hai già un prodotto e vuoi sviluppare nuove funzionalità? Possiamo diventare parte integrante del tuo team, lavorando in sinergia per creare nuove feature e convalidarle insieme.",

      gridTitle2Coding: "Sviluppare direttamente l’intera applicazione",
      gridDescription2Coding:
        "Se hai già in mente cosa vuoi ottenere, possiamo sviluppare l'intera app per te e consegnarti il codice sorgente affinché i tuoi sviluppatori possano mantenerla.",

      gridTitle3Testing: "Fare end-to-end testing",
      gridDescription3Testing:
        "Automatizziamo l'end-to-end testing all'interno dei processi di CI/CD per assicurarci che non ci siano regressioni e che l'app non vada in produzione con problemi. ",

      gridTitle4Learning: "Formare il tuo team su Flutter",
      gridDescription4Learning:
        "Accompagniamo i tuoi Junior Flutter Developer nel loro percorso di crescita! Inoltre, dopo aver sviluppato la tua app ti aiutiamo a mantenerla: possiamo formare il tuo team su come abbiamo realizzato il progetto e su come programmare in Flutter secondo il nostro pattern.",
    },
    areYouInterested: {
      title: "Ti interessa uno dei nostri servizi?",
      description:
        "Contattaci per scoprire come possiamo aiutarti a realizzare la tua applicazione mobile!",
      buttonText: "INVIACI UNA RICHIESTA",
    },
    chooseMabiloft: {
      overtitle: "SVILUPPARE IN FLUTTER CON MABILOFT",
      title: "Perché scegliere Mabiloft?",
      gridTitle1Experience:
        "Perché abbiamo esperienza pluriennale nella realizzazione di app",
      gridDescription1Experience:
        "Mabiloft è nata sviluppando app native utilizzando Objective-C + Swift e Java + Kotlin. Negli anni, però, abbiamo scelto di utilizzare Flutter perché ci consente di sviluppare più rapidamente applicazioni di migliore qualità e di renderle più mantenibili. Con Flutter abbiamo eliminato la necessità di sviluppare la stessa applicazione due volte, una per iOS e una per Android, ottimizzando così il nostro lavoro.",
      gridTitle2Details:
        "Perché abbiamo un team che collabora e presta attenzione al dettaglio",
      gridDescription2Details:
        "Lavoriamo in team con il nostro dipartimento di design e questo ci permette di studiare ogni piccolo dettaglio. In questo modo, possiamo offrirti un prodotto finito di alta qualità che soddisfi pienamente le tue esigenze.",
      gridTitle3Quality: "Perché facciamo prodotti di alta qualità",
      gridDescription3Quality:
        "Siamo convinti che la qualità del nostro lavoro e l'esperienza di tanti anni nel creare app siano le ragioni principali per scegliere Mabiloft. Abbiamo sviluppato numerose applicazioni in Flutter, personalizzando ogni dettaglio per soddisfare le esigenze dei nostri clienti.",
      gridTitle4Test:
        "Perché possiamo creare test automation direttamente sulle app",
      gridDescription4Test:
        "Non ci limitiamo a costruire la tua applicazione, ma ci occupiamo anche di end-to-end testing per garantire che la tua applicazione sia affidabile e funzioni correttamente.",
    },
    caseStudies: {
      overtitle: "PROGETTI",
      title: "Non ti abbiamo ancora convinto?",
      description: "Ecco alcuni dei nostri progetti realizzati con Flutter",
      caseDescription1Nowr:
        "L'applicazione che ti fa uscire di casa: scopri i migliori eventi sociali, di musica, arte e sport della tua città",
      caseDescription2Belvillage:
        "L’applicazione per controllare il tuo appartamento: la migliore user experience per il controllo domotico di appartamenti in tutto il mondo",
      caseDescription3AngelEye:
        "L’applicazione che gestisce la rilevazione degli annegamenti nelle piscine: aiuta a salvaguardare la sicurezza dei nuotatori",
      buttonText: "SCOPRI IL PROGETTO",
    },
    contactUs: {
      title: "Hai un’idea o vuoi conoscerci meglio?",
      buttonText: "Contattaci",
    },
  },
  en: {
    seo: {
      title: "App in Flutter",
      description:
        "Let's build together your iOS and Android app. With your Flutter application your business will get off the ground. Ask our help to develop your app!",
    },
    header: {
      overtitle: "Services",
      title: "Flutter app development",
      subtitle:
        "Speed up the development process, improve the quality and make the application more maintainable!",
    },
    lookingForDevs: {
      title:
        "If you're looking for experienced developers to create your mobile app in Flutter, you're in the right place.",
      description:
        "Mabiloft is a software house located in Padova (Italy), specialized in iOS and Android app development with Flutter, Google's framework.",
      buttonText: "Let's talk",
    },
    whyFlutter: {
      overtitle: "Technology",
      title: "Why do we use Flutter?",
      description:
        "Flutter is a mobile development framework created by Google. It allows to write code once and run on every platform. Thanks to Flutter, therefore, iOS and Android apps are created with the same codebase, tested just once and business logic is kept in a single part.",
    },
    ourServices: {
      overtitle: "Our services",
      title: "What can we do for you?",

      gridTitle1Features: "Build some features together",
      gridDescription1Features:
        "Do you already have a digital product and only want to add some features? We can become part and parcel of your team, working synergistically in order to create new features and validate them together.",

      gridTitle2Coding: "Develop the whole application",
      gridDescription2Coding:
        "If you already have a result in mind, we can develop the whole app for you and deliver the source code, so that your developers can maintain it.",

      gridTitle3Testing: "Test your app end-to-end",
      gridDescription3Testing:
        "We automatize end-to-end testing into the CI/CD processes to be sure that there are no regressions and the app doesn't go to production with open issues. ",

      gridTitle4Learning: "Train your team on Flutter",
      gridDescription4Learning:
        "We assist your Junior Flutter Developers in their learning journey! Also, once we have developed your app we help you maintain it: we can educate your team on how we realized the project and train them on how to code in Flutter according to our pattern.",
    },
    areYouInterested: {
      title: "Are you interested in one of our services?",
      description:
        "Contact us to find out how we can help you realize your mobile application!",
      buttonText: "Send a request",
    },
    chooseMabiloft: {
      overtitle: "Coding in Flutter with Mabiloft",
      title: "Why shoud you choose Mabiloft?",
      gridTitle1Experience:
        "Because we have long-standing expertise in app development",
      gridDescription1Experience:
        "Mabiloft was born developing native apps with Objective-C + Swift and Java + Kotlin. Across the years, though, we chose Flutter because it allows us to code high-quality apps faster and to make them easier to maintain. With Flutter we erased the necessity to code the same app twice, once for iOS and once for Android, optimizing our work.",
      gridTitle2Details:
        "Because we have a team which cooperates and pays attention to the details",
      gridDescription2Details:
        "We work as a team with our design department and that allows us to study every single detail. In this way, we can offer a valuable finished product that completely meets your expectations.",
      gridTitle3Quality: "Because we develop high-quality products",
      gridDescription3Quality:
        "We are convinced that the quality of our work and the multi-year experience in app development are the biggest reasons to choose Mabiloft. We developed multiple apps in Flutter, customizing every detail to fulfill the needs of our custumers.",
      gridTitle4Test:
        "Because we can create automation testing directly on the apps",
      gridDescription4Test:
        "We don't just develop your app, but also engage in end-to-end testing to assure that your application is reliable and works properly.",
    },
    caseStudies: {
      overtitle: "Projects",
      title: "Aren't you convinced yet?",
      description: "These are some of our projects realized in Flutter",
      caseDescription1Nowr:
        "The application that takes you out: find out about the best social events, musical events, art and sport in your city",
      caseDescription2Belvillage:
        "The application that makes you feel at home: the best user experience in home automation control for apartments all around the world",
      caseDescription3AngelEye:
        "The application to manage the detection of drownings in swimming pools: it helps to keep the swimmers safe",
      buttonText: "Find out more",
    },
    contactUs: {
      title: "Do you have any idea or do you want to know us better?",
      buttonText: "Contact us",
    },
  },
};
export const l_flutter = (
  trad: DeepKeyof<typeof traduction[keyof typeof traduction]>
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
