import { Box, Stack } from "@mui/material";

// @ts-ignore
import PhotoLineOne1 from "../../images/photo-line-1-1.png";
// @ts-ignore
import PhotoLineOne2 from "../../images/photo-line-1-2.png";
// @ts-ignore
import PhotoLineOne3 from "../../images/photo-line-1-3.png";
// @ts-ignore
import PhotoLineOne4 from "../../images/photo-line-1-4.png";
// @ts-ignore
import PhotoLineTwo1 from "../../images/photo-line-2-1.png";
// @ts-ignore
import PhotoLineTwo2 from "../../images/photo-line-2-2.png";
// @ts-ignore
import PhotoLineTwo3 from "../../images/photo-line-2-3.png";
// @ts-ignore
import PhotoLineTwo4 from "../../images/photo-line-2-4.png";

export const PhotoLineOne = () => {
  const figmaDisplay = 1750;

  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        flexShrink: 0,
        width: "120%",
        // overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: `${(204 * 100) / figmaDisplay}%`,
          aspectRatio: "204 / 248",
          backgroundColor: "#F1F4FF",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(331 * 100) / figmaDisplay}%`,
          aspectRatio: "331 / 249",
          backgroundImage: `url(${PhotoLineOne2})`,
          backgroundSize: "cover",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(390 * 100) / figmaDisplay}%`,
          aspectRatio: "390 / 217",
          backgroundColor: "#57D6F9",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(322 * 100) / figmaDisplay}%`,
          aspectRatio: "322 / 241",
          backgroundImage: `url(${PhotoLineOne1})`,
          backgroundSize: "cover",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(320 * 100) / figmaDisplay}%`,
          aspectRatio: "320 / 248",
          backgroundImage: `url(${PhotoLineOne3})`,
          backgroundSize: "cover",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(203 * 100) / figmaDisplay}%`,
          aspectRatio: "203 / 266",
          backgroundColor: "#B745F6",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(418 * 100) / figmaDisplay}%`,
          aspectRatio: "418 / 248",
          backgroundImage: `url(${PhotoLineOne4})`,
          backgroundSize: "cover",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(390 * 100) / figmaDisplay}%`,
          aspectRatio: "390 / 266",
          backgroundColor: "#FF7A00",
          borderRadius: "180px",
        }}
      />
    </Stack>
  );
};

export const PhotoLineTwo = () => {
  const figmaDisplay = 1750;

  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        flexShrink: 0,
        width: "120%",

        // overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: `${(390 * 100) / figmaDisplay}%`,
          aspectRatio: "390 / 217",
          backgroundColor: "#B745F6",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(287 * 100) / figmaDisplay}%`,
          aspectRatio: "287 / 248",
          backgroundImage: `url(${PhotoLineTwo1})`,
          backgroundSize: "cover",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(204 * 100) / figmaDisplay}%`,
          aspectRatio: "204 / 248",
          backgroundColor: "#F1F4FF",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(204 * 100) / figmaDisplay}%`,
          aspectRatio: "204 / 248",
          backgroundColor: "#FF7A00",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(336 * 100) / figmaDisplay}%`,
          aspectRatio: "336 / 251",
          backgroundImage: `url(${PhotoLineTwo2})`,
          backgroundSize: "cover",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(330 * 100) / figmaDisplay}%`,
          aspectRatio: "330 / 248",
          backgroundImage: `url(${PhotoLineTwo3})`,
          backgroundSize: "cover",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(204 * 100) / figmaDisplay}%`,
          aspectRatio: "204 / 248",
          backgroundColor: "#57D6F9",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(415 * 100) / figmaDisplay}%`,
          aspectRatio: "415 / 248",
          backgroundImage: `url(${PhotoLineTwo4})`,
          backgroundSize: "cover",
          borderRadius: "180px",
        }}
      />
      {/* <Box
        sx={{
          width: `${(204 * 100) / figmaDisplay}%`,
          aspectRatio: "204 / 248",
          backgroundColor: "#F1F4FF",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(322 * 100) / figmaDisplay}%`,
          aspectRatio: "322 / 241",
          backgroundColor: "#F1F4FF",
          backgroundImage: `url(${PhotoLineOne1})`,
          backgroundSize: "cover",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(320 * 100) / figmaDisplay}%`,
          aspectRatio: "320 / 248",
          backgroundColor: "#FF7A00",
          backgroundImage: `url(${PhotoLineOne3})`,
          backgroundSize: "cover",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(203 * 100) / figmaDisplay}%`,
          aspectRatio: "203 / 266",
          backgroundColor: "#B745F6",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          width: `${(418 * 100) / figmaDisplay}%`,
          aspectRatio: "418 / 248",
          backgroundColor: "#57D6F9",
          backgroundImage: `url(${PhotoLineOne4})`,
          backgroundSize: "cover",
          borderRadius: "180px",
        }}
      />
      <Box
        sx={{
          // width: `${(203 * 100) / figmaDisplay}%`,
          // aspectRatio: "203 / 266",
          width: `${(390 * 100) / figmaDisplay}%`,
          aspectRatio: "390 / 266",
          backgroundColor: "#FF7A00",
          borderRadius: "180px",
        }}
      /> */}
    </Stack>
  );
};
