/** @jsxImportSource @emotion/react */
import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const PhotosSection = () => {
  return (
    <Box
      sx={{
        flex: 1,
        position: "relative",
        // background: "lightgrey",
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: "center", md: "end" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyItems: "center",
          aspectRatio: { md: "1 / 1.13", xs: undefined },
          maxWidth: { md: "620px", xs: "530px" },
          // background: "lightblue",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              position: "relative",
              flex: 2.3,
            }}
          >
            <StaticImage
              src={"../../images/mosaic-left-top.png"}
              alt={""}
              imgStyle={{
                width: "100%",
                borderRadius: "120px",
              }}
            />
          </Box>
          <Box
            sx={{
              borderRadius: "120px",
              flex: 1,
              background: "#57D6F9",
            }}
          ></Box>
        </Stack>

        <Stack
          sx={{
            flexDirection: "row",
            flex: 1,
            display: { md: "flex", xs: "none" },
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <Box
              sx={{
                flex: 1.5,
                // width: "240px",
                borderRadius: "120px",
              }}
            >
              <StaticImage
                src={"../../images/mosaic-left-center.png"}
                alt={""}
                imgStyle={{
                  width: "100%",
                  borderRadius: "120px",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#FF7A00",
                flex: 1,
                borderRadius: "120px",
              }}
            ></Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                backgroundColor: "#B745F6",
                width: "100%",
                borderRadius: "120px",
                flex: 1,
                minHeight: "120px",
              }}
            ></Box>
            <Box sx={{ flex: 1 }}>
              <StaticImage
                src={"../../images/mosaic-right-bottom.png"}
                alt={""}
                imgStyle={{ borderRadius: "120px" }}
              />
            </Box>
          </Box>
        </Stack>

        <Stack
          sx={{ display: { xs: "flex", md: "none" }, flexDirection: "row" }}
        >
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#FF7A00",
              flex: 1,
              borderRadius: "120px",
            }}
          ></Box>
          <Box sx={{ flex: 2.3 }}>
            <StaticImage
              src={"../../images/mosaic-right-bottom.png"}
              alt={""}
              imgStyle={{ borderRadius: "120px", width: "100%" }}
            />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
export default PhotosSection;
