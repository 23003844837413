function ButtonDotIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={8} cy={8} r={3} fill="#E85322" />
    </svg>
  );
}

export default ButtonDotIcon;
