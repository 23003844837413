import { Box } from "@mui/material";
import FlutterTypography from "../../theme/FlutterTypography";
import { l_flutter } from "../../locales";

const Title = () => {
  return (
    <Box>
      <FlutterTypography variant="overtitle" sx={{ marginBottom: "32px" }}>
        {l_flutter("chooseMabiloft.overtitle")}
      </FlutterTypography>
      <FlutterTypography variant="title2">
        {l_flutter("chooseMabiloft.title")}
      </FlutterTypography>
    </Box>
  );
};

export default Title;
