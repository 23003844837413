import { Box } from "@mui/material";
import Title from "./Title";
import ReasonsGrid from "./ReasonsGrid";

const ChooseMabiloft = () => {
  return (
    <Box
      sx={{
        margin: "auto",
        maxWidth: "1800px",
        width: "90%",
        paddingTop: { md: "200px", xs: "64px" },
        paddingBottom: { md: "220px", xs: "72px" },
      }}
    >
      <Title />
      <ReasonsGrid />
    </Box>
  );
};

export default ChooseMabiloft;
