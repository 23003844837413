import { Stack, useMediaQuery, useTheme } from "@mui/material";
import TechnologiesGrid from "./TechnologiesGrid";
import Title from "./Title";

const WhatCanWeDoForYou = () => {
  return (
    <Stack
      sx={{
        maxWidth: "1800px",
        flexDirection: { lg: "row", xs: "column" },
        width: "90%",
        margin: "auto",
        paddingTop: { lg: "120px", xs: "64px" },
        paddingBottom: { lg: "80px", xs: "120px" },
        // backgroundColor: "oldlace",
        position: "relative",
        gap: { xs: "16px", lg: "32px" },
        justifyContent: "space-between",
      }}
    >
      <Title />
      <TechnologiesGrid />
    </Stack>
  );
};

export default WhatCanWeDoForYou;
