import { HeadProps } from "gatsby";
import Flutter from "../../features/flutter";
import SEOHead, { initHeadLanguage } from "../../components/old/seo_head";
import { l_flutter } from "../../features/flutter/locales";

export default Flutter;

export const Head = (headProps: HeadProps) => {
  initHeadLanguage(headProps);

  return (
    <SEOHead
      title={l_flutter("seo.title")}
      description={l_flutter("seo.description")}
      headProps={headProps}
    />
  );
};
