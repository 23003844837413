import Footer from "../../components/shared/Footer/Footer";
import AreYouInterested from "./components/AreYouInterested/AreYouInterested";
import CaseStudies from "./components/CaseStudies/CaseStudies";
import ChooseMabiloft from "./components/ChooseMabiloft/ChooseMabiloft";
import ContactUs from "./components/ContactUs/ContactUs";
import Header from "./components/Header/Header";
import LookingForDevs from "./components/LookingForDevs/LookingForDevs";
import PhotosLine from "./components/PhotosLines/PhotosLine";
import ScrollingPhotos from "./components/ScrollingPhotos/ScrollingPhotos";
import WhatCanWeDoForYou from "./components/WhatCanWeDoForYou/WhatCanWeDoForYou";
import WhyFlutter from "./components/WhyFlutter/WhyFlutter";

const Flutter = () => {
  return (
    <>
      <Header />
      <LookingForDevs />
      <ScrollingPhotos />
      <WhyFlutter />
      <WhatCanWeDoForYou />
      <AreYouInterested />
      <ChooseMabiloft />
      <CaseStudies />
      <PhotosLine />
      <ContactUs />
      <Footer withoutTopSection />
    </>
  );
};

export default Flutter;
