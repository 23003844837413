import { Box, Stack } from "@mui/material";
import Description from "./Description";
import Title from "./Title";

const LookingForDevs = () => {
  return (
    <Box sx={{ width: "100%", backgroundColor: "#fff", position: "relative" }}>
      <Stack
        sx={{
          maxWidth: "1800px",
          width: "90%",
          margin: "auto",
          flexDirection: { md: "row", xs: "column" },
          gap: "24px",
          paddingTop: { md: "80px", xs: "40px" },
          paddingBottom: { md: "80px", xs: "40px" },
          // border: "1px solid red",
        }}
      >
        <Title />
        <Description />
      </Stack>
    </Box>
  );
};
export default LookingForDevs;
