import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// @ts-ignore
import ScrollingPhotoLeft from "../../images/scrolling-left.png";
// @ts-ignore
import ScrollingPhotoRight from "../../images/scrolling-right.png";

gsap.registerPlugin(ScrollTrigger);

const ScrollingPhotos = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    const scrollAnimationLeft = gsap.fromTo(
      ".scrolling-left",
      {
        backgroundPosition: "50% 0%",
      },
      {
        scrollTrigger: {
          trigger: ".scrolling-pics-container",
          scrub: 1,
          start: "top 20%",
          end: "bottom bottom",
        },
        backgroundPosition: "50% 50%",
      }
    );
    const scrollAnimationRight = gsap.fromTo(
      ".scrolling-right",
      {
        backgroundPosition: "50% 0%",
      },
      {
        scrollTrigger: {
          trigger: ".scrolling-pics-container",
          scrub: 1,
          start: isOverMd ? "top 20%" : "top 80%",
          end: isOverMd ? "bottom bottom" : "bottom 10%",
        },
        backgroundPosition: "50% 70%",
      }
    );
    return () => {
      scrollAnimationLeft.kill();
      scrollAnimationRight.kill();
    };
  }, [isOverMd]);

  return (
    <Box
      sx={{
        paddingY: { md: "200px", xs: "64px" },
        width: "90%",
        maxWidth: "1800px",
        display: "flex",
        margin: "auto",
        gap: "32px",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
      className="scrolling-pics-container"
    >
      <Box
        sx={{
          display: { md: "block", xs: "none" },
          backgroundImage: `url(${ScrollingPhotoLeft})`,
          backgroundPosition: "50% 10%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "115% auto",
          aspectRatio: `${715 / 500} / 1`,
          maxWidth: "715px",
          flex: 1.05,
          borderRadius: "16px",
        }}
        className="scrolling-left"
      />
      <Box
        sx={{
          position: "relative",
          backgroundImage: `url(${ScrollingPhotoRight})`,
          marginTop: { md: "120px", xs: 0 },
          flex: 1,
          aspectRatio: `${707 / 502} / 1`,
          maxWidth: "705px",
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: { md: "115% auto", xs: "120% auto" },
          borderRadius: "16px",
        }}
        className="scrolling-right"
      />
    </Box>
  );
};

export default ScrollingPhotos;
