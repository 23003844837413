import { Box } from "@mui/material";
import FlutterTypography from "../../theme/FlutterTypography";
import { l_flutter } from "../../locales";

const Title = () => {
  return (
    <Box
      sx={{
        width: { lg: "50%", xs: "100%" },
        maxWidth: { lg: "480px", xs: "unset" },
        boxSizing: "border-box",
        flexShrink: 0,
      }}
    >
      <FlutterTypography
        variant="overtitle"
        sx={{ marginBottom: { lg: "32px", xs: "24px" } }}
      >
        {l_flutter("ourServices.overtitle")}
      </FlutterTypography>
      <FlutterTypography variant="title2">
        {l_flutter("ourServices.title")}
      </FlutterTypography>
    </Box>
  );
};
export default Title;
