import { Box, Button } from "@mui/material";

import FlutterTypography from "../../theme/FlutterTypography";
import { l_careers } from "../../../careers/locales";
import { l_flutter } from "../../locales";

const Title = () => {
  return (
    <Box
      sx={{
        // padding: "24px",
        width: { md: "46%", xs: "100%" },
        minWidth: { md: "395px", xs: "unset" },
        boxSizing: "border-box",
      }}
    >
      <FlutterTypography variant="title1">
        {l_flutter("lookingForDevs.title")}
      </FlutterTypography>
    </Box>
  );
};
export default Title;
