import { Box, Stack } from "@mui/material";
import { PhotoLineOne, PhotoLineTwo } from "./PhotoLinesOneAndTwo";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

const PhotosLine = () => {
  useEffect(() => {
    const moveLineOne = gsap.fromTo(
      ".photo-line-one",
      { x: 0 },
      {
        scrollTrigger: {
          trigger: ".photos-line-container",
          start: "top bottom",
          scrub: 2,
          end: "bottom top",
        },
        x: "-40%",
      }
    );
    const moveLineTwo = gsap.fromTo(
      ".photo-line-two",
      { x: "-100%" },
      {
        scrollTrigger: {
          trigger: ".photos-line-container",
          start: "top bottom",
          scrub: 2,
          end: "bottom top",
        },
        x: "-60%",
      }
    );
    return () => {
      moveLineOne.kill();
      moveLineTwo.kill();
    };
  }, []);

  return (
    <Stack
      sx={{
        overflow: "hidden",
        gap: "24px",
        display: { md: "flex", xs: "none" },
      }}
      className="photos-line-container"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
        }}
        className="photo-line-one"
      >
        <PhotoLineOne />
        <PhotoLineOne />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
        }}
        className="photo-line-two"
      >
        <PhotoLineTwo />
        <PhotoLineTwo />
      </Box>
    </Stack>
  );
};

export default PhotosLine;
