import { Box, Button, lighten } from "@mui/material";
import FlutterTypography from "../../theme/FlutterTypography";
import { l_flutter } from "../../locales";
import { StaticImage } from "gatsby-plugin-image";
import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { LocalizedLink } from "../../../../components/LocalizedLink";

gsap.registerPlugin(ScrollTrigger);

const ContactUs = () => {
  useEffect(() => {
    const movePurpleCircle = gsap.to(".contact-us-purple-circle", {
      scrollTrigger: {
        trigger: ".contact-us-purple-circle",
        start: "top 80%",
        end: "bottom center",
        scrub: 2,
      },
      x: 10,
      y: -50,
    });
    const moveOrangeLemon = gsap.to(".contact-us-orange-lemon", {
      scrollTrigger: {
        trigger: ".contact-us-orange-lemon",
        start: "top 80%",
        end: "bottom center",
        scrub: 2,
      },
      x: -20,
      y: -40,
      rotate: 130,
    });
    const moveBlueLemon = gsap.to(".contact-us-blue-lemon", {
      scrollTrigger: {
        trigger: ".contact-us-blue-lemon",
        start: "top 80%",
        end: "bottom center",
        scrub: 2,
      },
      x: 40,
      y: 5,
      rotate: -120,
    });

    return () => {
      moveBlueLemon.kill();
      moveOrangeLemon.kill();
      movePurpleCircle.kill();
    };
  }, []);

  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: "1800px",
        margin: "auto",
        paddingTop: { md: "240px", xs: "140px" },
        paddingBottom: { md: "180px", xs: "80px" },
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        justifyContent: "space-between",
        alignItems: "center",
        gap: { md: "32px", xs: "42px" },
        position: "relative",
      }}
    >
      <FlutterTypography
        variant="title2"
        sx={{
          fontSize: { lg: "80px", md: "70px", xs: "36px" },
          lineHeight: { lg: "100px", md: "84px", xs: "44px" },
          maxWidth: "900px",
        }}
      >
        {l_flutter("contactUs.title")}
      </FlutterTypography>
      <LocalizedLink
        to={"/contacts"}
        style={{
          textDecoration: "none",
          color: "white",
          flexShrink: 0,
        }}
      >
        <Button
          sx={{
            backgroundColor: "black",
            textDecoration: "none",
            padding: { lg: "32px 120px", md: "32px 80px", xs: "16px 120px" },
            textTransform: "unset",
            color: "white",
            fontSize: { lg: "36px", md: "32px", xs: "24px" },
            lineHeight: { lg: "36px", md: "32px", xs: "24px" },
            borderRadius: "100px",
            ":hover": {
              backgroundColor: lighten("#000", 0.12),
            },
          }}
        >
          {l_flutter("contactUs.buttonText")}
        </Button>
      </LocalizedLink>

      <Box
        sx={{
          position: "absolute",
          width: { lg: "44px", md: "30px", xs: "12px" },
          top: { xs: "80px", md: "150px" },
          left: { xs: "10%", md: "60%" },
        }}
        className="contact-us-purple-circle"
      >
        <StaticImage src="../../images/purple-circle.svg" alt="" width={44} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: { lg: "80px", md: "60px", xs: "28px" },
          bottom: { xs: "70%", md: "10%" },
          right: { xs: "5%", md: "20%" },
          rotate: "-315deg",
        }}
        className="contact-us-orange-lemon"
      >
        <StaticImage src="../../images/orange-lemon.svg" alt="" width={80} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: { lg: "65px", md: "50px", xs: "40px" },
          top: { xs: "85%", md: "10%" },
          right: { xs: "80%", md: "10%" },
          rotate: "-45deg",
        }}
        className="contact-us-blue-lemon"
      >
        <StaticImage src="../../images/blue-lemon.svg" alt="" width={65} />
      </Box>
    </Box>
  );
};

export default ContactUs;
