import { Box } from "@mui/material";

import { l_flutter } from "../../locales";
import FlutterTypography from "../../theme/FlutterTypography";

const Title = () => {
  return (
    <Box
      sx={{
        width: { md: "55%", xs: "100%" },
        boxSizing: "border-box",
      }}
    >
      <FlutterTypography
        variant="title2"
        sx={{
          marginBottom: "24px",
          maxWidth: { md: "530px", xs: "unset" },
        }}
      >
        {l_flutter("areYouInterested.title")}
      </FlutterTypography>

      <FlutterTypography
        variant="body1"
        sx={{ marginBottom: "24px", maxWidth: "700px" }}
      >
        {l_flutter("areYouInterested.description")}
      </FlutterTypography>
    </Box>
  );
};
export default Title;
