import { Typography, TypographyProps } from "@mui/material";

type CareerTypographyProps = {
  variant?: "title1" | "title2" | "overtitle" | "body1" | "body2" | "span";
} & Omit<TypographyProps, "variant" | "color">;

const FlutterTypography: React.FC<CareerTypographyProps> = ({
  variant,
  sx,
  ...props
}) => {
  return (
    <Typography
      component={variant === "span" ? "span" : "p"}
      sx={{
        color: "#000",
        ...(variant === "title1"
          ? {
              fontSize: { md: "36px", xs: "24px" },
              lineHeight: { md: "44px", xs: "32px" },
              fontWeight: 700,
            }
          : variant === "title2"
          ? {
              fontSize: { md: "64px", xs: "36px" },
              lineHeight: { md: "80px", xs: "44px" },
              fontWeight: 700,
            }
          : variant === "overtitle"
          ? {
              fontSize: { md: "21px", xs: "12px" },
              lineHeight: { md: "22px", xs: "12px" },
              fontWeight: 400,
              textTransform: "uppercase",
            }
          : variant === "body1"
          ? {
              fontSize: { md: "24px", xs: "16px" },
              lineHeight: { md: "36px", xs: "20px" },
              fontWeight: 400,
              color: "#00000074",
            }
          : variant === "body2"
          ? {
              fontSize: "20px",
              lineHeight: "28px",
              fontWeight: 400,
              color: "#00000060",
            }
          : variant === "span"
          ? {
              fontSize: "inherit",
              lineHeight: "inherit",
              fontWeight: "inherit",
            }
          : {}),
        ...sx,
      }}
      {...props}
    />
  );
};
export default FlutterTypography;
