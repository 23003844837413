import { Box, Stack } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { ReactNode, useEffect } from "react";
import { l_flutter } from "../../locales";
import FlutterTypography from "../../theme/FlutterTypography";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ReasonsGridPoint = ({
  reason,
  pointNumber,
}: {
  reason: {
    title: string;
    description: string;
    picture: ReactNode;
    pictureSmall: ReactNode;
    decorations?: ReactNode;
  };
  pointNumber: number;
}) => {
  return (
    <>
      <Box
        sx={{
          width: { md: "50%", xs: "100%" },
          maxWidth: { md: "720px", xs: "unset" },
        }}
      >
        <FlutterTypography
          sx={{
            fontWeight: 700,
            fontSize: { md: "36px", xs: "16px" },
            lineHeight: { md: "44px", xs: "20px" },
            marginBottom: { md: "32px", xs: "16px" },
          }}
        >
          {reason.title}
        </FlutterTypography>
        <FlutterTypography variant="body1" sx={{ color: "#000" }}>
          {reason.description}
        </FlutterTypography>
      </Box>
      <Box
        sx={{
          display: { md: "block", xs: "none" },
          borderRadius: "400px",
          borderBottomLeftRadius: pointNumber === 1 ? "32px" : "400px",

          borderBottomRightRadius: pointNumber === 1 ? "32px" : "400px",

          borderTopLeftRadius: pointNumber === 2 ? "32px" : "400px",

          borderTopRightRadius: pointNumber === 2 ? "32px" : "400px",
          overflow: "hidden",
          width: "50%",
          maxWidth: "480px",
        }}
      >
        {reason.picture}
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "none" },
          borderRadius: "48px",
          overflow: "hidden",
          width: "100%",
          maxWidth: "450px",
        }}
      >
        {reason.pictureSmall}
      </Box>
      {reason.decorations}
    </>
  );
};

const ReasonsGrid = () => {
  useEffect(() => {
    const animatePurpleCircle = gsap.to(".grid-purple-circle", {
      scrollTrigger: {
        trigger: ".grid-purple-circle",
        scrub: 2,
        start: "top 80%",
      },
      x: 10,
      y: 20,
    });
    const animateOrangeLemon = gsap.to(".grid-orange-lemon", {
      scrollTrigger: {
        trigger: ".grid-orange-lemon",
        scrub: 2,
        start: "top 80%",
      },
      x: 30,
      y: -50,
      rotate: 30,
    });
    const animatePurpleLemon = gsap.to(".grid-purple-lemon", {
      scrollTrigger: {
        trigger: ".grid-purple-lemon",
        scrub: 2,
        start: "top 80%",
      },
      x: -50,
      y: -10,
      rotate: 60,
    });
    const animateBlueCircle = gsap.to(".grid-blue-circle", {
      scrollTrigger: {
        trigger: ".grid-blue-circle",
        scrub: 2,
        start: "top 80%",
      },
      x: -40,
      y: -20,
    });
    const animateAnotherOrangeLemon = gsap.to(".grid-another-orange-lemon", {
      scrollTrigger: {
        trigger: ".grid-another-orange-lemon",
        scrub: 2,
        start: "top 80%",
      },
      x: 30,
      y: -20,
      rotate: 30,
    });

    return () => {
      animatePurpleCircle.kill();
      animateOrangeLemon.kill();
      animateAnotherOrangeLemon.kill();
      animatePurpleLemon.kill();
      animateBlueCircle.kill();
    };
  }, []);

  const reasons = [
    {
      title: l_flutter("chooseMabiloft.gridTitle1Experience"),
      description: l_flutter("chooseMabiloft.gridDescription1Experience"),
      picture: (
        <StaticImage
          alt=""
          src="../../images/choose-mabiloft-1.png"
          width={485}
          imgStyle={{}}
        />
      ),
      pictureSmall: (
        <StaticImage
          alt=""
          src="../../images/choose-mabiloft-1-sm.png"
          width={450}
        />
      ),
      decorations: (
        <>
          <Box
            sx={{
              display: {
                md: "block",
                xs: "none",
              },
              position: "absolute",
              width: "18px",
              bottom: 0,
              left: "50%",
            }}
            className="grid-purple-circle"
          >
            <StaticImage
              alt=""
              src="../../images/purple-circle.svg"
              width={18}
            />
          </Box>
        </>
      ),
    },
    {
      title: l_flutter("chooseMabiloft.gridTitle2Details"),
      description: l_flutter("chooseMabiloft.gridDescription2Details"),
      picture: (
        <StaticImage
          alt=""
          src="../../images/choose-mabiloft-2.png"
          width={485}
        />
      ),
      pictureSmall: (
        <StaticImage
          alt=""
          src="../../images/choose-mabiloft-2-sm.png"
          width={450}
        />
      ),
      decorations: (
        <>
          <Box
            sx={{
              display: {
                md: "block",
                xs: "none",
              },
              position: "absolute",
              width: "35px",
              top: -30,
              left: "30%",
              rotate: "-20deg",
            }}
            className="grid-orange-lemon"
          >
            <StaticImage
              alt=""
              src="../../images/orange-lemon.svg"
              width={35}
            />
          </Box>
        </>
      ),
    },
    {
      title: l_flutter("chooseMabiloft.gridTitle3Quality"),
      description: l_flutter("chooseMabiloft.gridDescription3Quality"),
      picture: (
        <StaticImage
          alt=""
          src="../../images/choose-mabiloft-3.png"
          width={485}
        />
      ),
      pictureSmall: (
        <StaticImage
          alt=""
          src="../../images/choose-mabiloft-3-sm.png"
          width={450}
        />
      ),
      decorations: (
        <>
          <Box
            sx={{
              display: {
                md: "block",
                xs: "none",
              },
              position: "absolute",
              width: "45px",
              top: -50,
              left: "30%",
              rotate: "180deg",
            }}
            className="grid-purple-lemon"
          >
            <StaticImage
              alt=""
              src="../../images/purple-lemon.svg"
              width={45}
            />
          </Box>
          <Box
            sx={{
              display: {
                md: "block",
                xs: "none",
              },
              position: "absolute",
              width: "20px",
              bottom: -50,
              right: "30%",
            }}
            className="grid-blue-circle"
          >
            <StaticImage alt="" src="../../images/blue-circle.svg" width={20} />
          </Box>
        </>
      ),
    },
    {
      title: l_flutter("chooseMabiloft.gridTitle4Test"),
      description: l_flutter("chooseMabiloft.gridDescription4Test"),
      picture: (
        <StaticImage
          alt=""
          src="../../images/choose-mabiloft-4.png"
          width={485}
        />
      ),
      pictureSmall: (
        <StaticImage
          alt=""
          src="../../images/choose-mabiloft-4-sm.png"
          width={450}
        />
      ),
      decorations: (
        <>
          <Box
            sx={{
              display: {
                md: "block",
                xs: "none",
              },
              position: "absolute",
              width: "45px",
              bottom: -40,
              left: "50%",
              rotate: "-50deg",
            }}
            className="grid-another-orange-lemon"
          >
            <StaticImage
              alt=""
              src="../../images/orange-lemon.svg"
              width={45}
            />
          </Box>
        </>
      ),
    },
  ];

  return (
    <Stack sx={{ paddingTop: { md: "80px", xs: "42px" }, gap: "80px" }}>
      {reasons.map((reason, i) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              md: i % 2 === 0 ? "row" : "row-reverse",
              xs: "column-reverse",
            },
            gap: { md: "48px", xs: "24px" },
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
          }}
          key={`reason${i}`}
        >
          <ReasonsGridPoint reason={reason} pointNumber={i} />
        </Box>
      ))}
    </Stack>
  );
};

export default ReasonsGrid;
