import { Box } from "@mui/material";
import PhotosSection from "./PhotosSection";
import Title from "./Title";

const Header = () => {
  return (
    <Box
      sx={{
        maxWidth: "1800px",
        width: "90%",
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        gap: { md: 0, xs: "40px" },
        margin: "auto",
        marginTop: { md: "100px", xs: "64px" },
        marginBottom: { md: "120px", xs: "64px" },
        justifyContent: { md: "space-around", xs: "center" },
      }}
    >
      <Title />
      <PhotosSection />
    </Box>
  );
};
export default Header;
