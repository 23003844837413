import { Box, Stack } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { ReactNode, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import FlutterTypography from "../../theme/FlutterTypography";
import { l_flutter } from "../../locales";

gsap.registerPlugin(ScrollTrigger);

const GridPoint = ({
  image,
  title,
  description,

  id,
}: {
  image: ReactNode;
  title: string;
  description: string;

  id: string;
}) => {
  useEffect(() => {
    gsap.fromTo(
      `.RandDGridPoint${id}`,
      {
        opacity: 0,
      },
      {
        scrollTrigger: {
          trigger: `.RandDGridPoint${id}`,
          scrub: 2,
          start: "-300px center",
          end: "center center",
        },
        opacity: 1,
      }
    );
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { lg: "row", xs: "column" },
        maxWidth: "900px",
        gap: { lg: "40px", xs: "24px" },
        // borderBottom: hasBorder
        //   ? "1px solid rgba(0, 0, 0, .06)"
        //   : "1px solid transparent",
        paddingTop: "32px",
        alignItems: "flex-start",
      }}
      className={`RandDGridPoint${id}`}
    >
      <Box sx={{ width: "40px", flexShrink: 0, marginTop: "6px" }}>{image}</Box>

      <Box>
        <FlutterTypography variant="title1" sx={{ marginBottom: "8px" }}>
          {title}
        </FlutterTypography>
        <FlutterTypography
          variant="body2"
          sx={{
            marginBottom: { lg: "80px", xs: "24px" },
            color: "rgba(0, 0, 0, .6)",
          }}
        >
          {description}
        </FlutterTypography>
      </Box>
    </Box>
  );
};

const TechnologiesGrid = () => {
  const services = [
    {
      title: l_flutter("ourServices.gridTitle1Features"),
      description: l_flutter("ourServices.gridDescription1Features"),
      image: (
        <StaticImage src="../../images/grid-symbol-1.png" alt="" width={40} />
      ),
    },
    {
      title: l_flutter("ourServices.gridTitle2Coding"),
      description: l_flutter("ourServices.gridDescription2Coding"),
      image: (
        <StaticImage src="../../images/grid-symbol-2.png" alt="" width={40} />
      ),
    },
    {
      title: l_flutter("ourServices.gridTitle3Testing"),
      description: l_flutter("ourServices.gridDescription3Testing"),
      image: (
        <StaticImage src="../../images/grid-symbol-3.png" alt="" width={40} />
      ),
    },
    {
      title: l_flutter("ourServices.gridTitle4Learning"),
      description: l_flutter("ourServices.gridDescription4Learning"),
      image: (
        <StaticImage src="../../images/grid-symbol-4.png" alt="" width={40} />
      ),
    },
  ];

  return (
    <Box>
      <Stack sx={{ flexDirection: "column" }}>
        {services.map((service, i) => (
          <GridPoint
            key={service.title}
            image={service.image}
            title={service.title}
            description={service.description}
            id={i.toString()}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default TechnologiesGrid;
