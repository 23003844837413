import { Box } from "@mui/material";
import React from "react";
import FlutterTypography from "../../theme/FlutterTypography";
import { l_flutter } from "../../locales";

const Title = () => {
  return (
    <Box sx={{ maxWidth: "660px", marginBottom: { md: "80px", xs: "48px" } }}>
      <FlutterTypography
        variant="overtitle"
        sx={{ marginBottom: { md: "24px", xs: "32px" } }}
      >
        {l_flutter("caseStudies.overtitle")}
      </FlutterTypography>
      <FlutterTypography
        variant="title2"
        sx={{ marginBottom: { md: "24px", xs: "32px" } }}
      >
        {l_flutter("caseStudies.title")}
      </FlutterTypography>
      <FlutterTypography
        sx={{
          color: "#000",
          fontSize: "24px",
          lineHeight: "32px",
          fontWeight: 400,
        }}
      >
        {l_flutter("caseStudies.description")}
      </FlutterTypography>
    </Box>
  );
};

export default Title;
